import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.get_notifications_by_page()
            },
          },
        },
        [
          _c(VTextField, {
            staticClass: "mx-auto",
            staticStyle: {
              width: "100%",
              "max-width": "500px",
              "border-radius": "0px !important",
            },
            attrs: {
              label: _vm.$t("search"),
              "append-icon": "mdi-magnify",
              filled: "",
              dense: "",
              "hide-details": "",
            },
            on: { "click:append": _vm.get_notifications_by_page },
            model: {
              value: _vm.searchFilter,
              callback: function ($$v) {
                _vm.searchFilter = $$v
              },
              expression: "searchFilter",
            },
          }),
        ],
        1
      ),
      _vm.loading
        ? _c(
            VRow,
            { staticClass: "mt-4", attrs: { justify: "center" } },
            [
              _c(VProgressCircular, {
                attrs: {
                  indeterminate: "",
                  size: "20",
                  color: "primary",
                  "data-test": "loading",
                },
              }),
            ],
            1
          )
        : _vm.notifications.length == 0
        ? _c("div", [
            _c("p", { staticClass: "not-found text-center my-10 dark-color" }, [
              _vm._v(" " + _vm._s(_vm.$t("request_not_found")) + " "),
            ]),
          ])
        : _c(
            "div",
            [
              !_vm.gs.isMobile()
                ? _c(
                    VSimpleTable,
                    { staticStyle: { background: "var(--white)" } },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("requester")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$tc("company", 1)))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("value")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("date")))]),
                          _c("th", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(_vm.$t("actions"))),
                          ]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.notifications, function (n, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "dark-color" }, [
                              _vm._v(_vm._s(_vm.gs.show_full_name(n.Sender))),
                            ]),
                            _c("td", { staticClass: "dark-color" }, [
                              _vm._v(_vm._s(n.CompanyInterest.Company.Name)),
                            ]),
                            _c(
                              "td",
                              {
                                staticClass: "dark-color",
                                attrs: {
                                  "data-test":
                                    "Notification:ReceivedTemplate:Value" +
                                    n.CompanyInterest.Value,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatToCurrency(
                                        n.CompanyInterest.Currency,
                                        n.CompanyInterest.Value
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("td", { staticClass: "dark-color" }, [
                              _vm._v(
                                _vm._s(_vm.gs.convert_date(n.Timestamp, true))
                              ),
                            ]),
                            _vm.Status == 0
                              ? _c(
                                  "td",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "font-awesome-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "received-table-action-icon received-table-check-icon",
                                                          attrs: {
                                                            icon: "fa-solid fa-check",
                                                            "data-test":
                                                              "Notification:ReceivedTemplate:Check:" +
                                                              n.CompanyInterest
                                                                .Value,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.manage_click(
                                                                n,
                                                                _vm
                                                                  .signatureNotificationsEnum
                                                                  .Confirm
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "font-awesome-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("confirm"))),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "font-awesome-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "received-table-action-icon received-table-edit-icon",
                                                          attrs: {
                                                            icon: "fa-solid fa-pencil",
                                                            "data-test":
                                                              "Notification:ReceivedTemplate:Edit:" +
                                                              n.CompanyInterest
                                                                .Value,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.manage_click(
                                                                n,
                                                                _vm
                                                                  .signatureNotificationsEnum
                                                                  .Edit
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "font-awesome-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("edit"))),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "font-awesome-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "received-table-action-icon received-table-refuse-icon",
                                                          attrs: {
                                                            icon: "fa-solid fa-xmark",
                                                            "data-test":
                                                              "Notification:ReceivedTemplate:Decline:" +
                                                              n.CompanyInterest
                                                                .Value,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.manage_click(
                                                                n,
                                                                _vm
                                                                  .signatureNotificationsEnum
                                                                  .Decline
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "font-awesome-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("decline"))),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "td",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "dxa-btn-secondary",
                                        staticStyle: {
                                          "margin-right": "12px !important",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.manage_click(n, null)
                                          },
                                        },
                                      },
                                      [
                                        _c(VIcon, { staticClass: "mr-1" }, [
                                          _vm._v("mdi-text-box-outline"),
                                        ]),
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("detail"))),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ])
                        }),
                        0
                      ),
                    ]
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "p",
                        {
                          staticClass: "my-2",
                          staticStyle: { color: "#9c9c9c" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("approvals_mobile_desc")) + " "
                          ),
                        ]
                      ),
                      _vm._l(_vm.notifications, function (n, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "mobile-request" },
                          [
                            _c(
                              "a",
                              {
                                staticStyle: { display: "flex" },
                                on: {
                                  click: function ($event) {
                                    return _vm.manage_click(n, null)
                                  },
                                },
                              },
                              [
                                _c(
                                  VCol,
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "justify-content": "start",
                                    },
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            color: "var(--dark)",
                                            "font-weight": "300",
                                            "font-size": "12px",
                                            "line-height": "15px",
                                            "letter-spacing": "0.15px",
                                            "margin-bottom": "2px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.gs.convert_date(
                                                  n.Timestamp,
                                                  true
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            color: "var(--dark)",
                                            "font-style": "normal",
                                            "font-weight": "normal",
                                            "font-size": "16px",
                                            "line-height": "20px",
                                            "letter-spacing": "0.15px",
                                            "margin-bottom": "2px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                n.CompanyInterest.Company.Name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            color: "var(--dark)",
                                            "font-weight": "300",
                                            "font-size": "12px",
                                            "line-height": "15px",
                                            "letter-spacing": "0.15px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.gs.show_full_name(n.Sender)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  VCol,
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "end",
                                      "align-items": "center",
                                    },
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "font-style": "normal",
                                          "font-weight": "bold",
                                          "font-size": "16px",
                                          "line-height": "20px",
                                          "text-align": "center",
                                          "letter-spacing": "0.15px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatToCurrency(
                                                n.CompanyInterest.Currency,
                                                n.CompanyInterest.Value
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
              _c(VPagination, {
                staticClass: "custom-pagination",
                attrs: { color: "primary", length: _vm.total_pages },
                on: { input: _vm.changePage },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          ),
      _vm.manageModal && !_vm.gs.isMobile()
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.manageModal,
                callback: function ($$v) {
                  _vm.manageModal = $$v
                },
                expression: "manageModal",
              },
            },
            [
              _c("ManageSignatureNotificationModal", {
                attrs: {
                  Notification: _vm.notification,
                  Option: _vm.manageOption,
                },
                on: { close: _vm.reset_modal, remove: _vm.remove_notification },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.manageModal && _vm.gs.isMobile()
        ? _c(
            VBottomSheet,
            {
              model: {
                value: _vm.manageModal,
                callback: function ($$v) {
                  _vm.manageModal = $$v
                },
                expression: "manageModal",
              },
            },
            [
              _c("ManageSignatureNotificationModal", {
                attrs: {
                  Notification: _vm.notification,
                  Option: _vm.manageOption,
                },
                on: { close: _vm.reset_modal, remove: _vm.remove_notification },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }