import { render, staticRenderFns } from "./ReceivedNotifications.vue?vue&type=template&id=4410fbac&scoped=true&"
import script from "./ReceivedNotifications.vue?vue&type=script&lang=js&"
export * from "./ReceivedNotifications.vue?vue&type=script&lang=js&"
import style0 from "./ReceivedNotifications.vue?vue&type=style&index=0&id=4410fbac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4410fbac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4410fbac')) {
      api.createRecord('4410fbac', component.options)
    } else {
      api.reload('4410fbac', component.options)
    }
    module.hot.accept("./ReceivedNotifications.vue?vue&type=template&id=4410fbac&scoped=true&", function () {
      api.rerender('4410fbac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/signatureNotifications/table/ReceivedNotifications.vue"
export default component.exports