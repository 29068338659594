import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "dxa_modal", attrs: { color: "white" } },
    [
      _c(
        VCardText,
        { staticClass: "pa-4 pt-0" },
        [
          _c(
            VRow,
            [
              _c(VCol, { attrs: { cols: "12", md: "4" } }, [
                _c("p", { staticClass: "field-header" }, [
                  _vm._v(_vm._s(_vm.$t("request_date"))),
                ]),
                _c("p", { staticClass: "field-value" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.gs.convert_date(_vm.notification.Timestamp, false)
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c(VCol, { attrs: { cols: "12", md: "4" } }, [
                _c("p", { staticClass: "field-header" }, [
                  _vm._v(_vm._s(_vm.$tc("company", 1))),
                ]),
                _c("p", { staticClass: "field-value" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.notification.CompanyInterest.Company.Name) +
                      " "
                  ),
                ]),
              ]),
              !_vm.gs.isMobile()
                ? _c(VCol, { attrs: { cols: "4" } })
                : _vm._e(),
              _c(VCol, { attrs: { cols: "12", md: "4" } }, [
                _c("p", { staticClass: "field-header" }, [
                  _vm._v(_vm._s(_vm.$t("requester"))),
                ]),
                _c("p", { staticClass: "field-value" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.gs.show_full_name(_vm.notification.Sender)) +
                      " "
                  ),
                ]),
              ]),
              !_vm.gs.isMobile()
                ? _c(VCol, { attrs: { cols: "4" } })
                : _vm._e(),
              _c(
                VCol,
                {
                  class: _vm.gs.isMobile() ? "text-left" : "text-center",
                  attrs: { cols: "12", md: "4" },
                },
                [
                  _c("p", { staticClass: "field-header" }, [
                    _vm._v(_vm._s(_vm.$t("value"))),
                  ]),
                  _c("p", { staticClass: "request-value" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatToCurrency(
                            _vm.notification.CompanyInterest.Currency,
                            _vm.notification.CompanyInterest.Value
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(VDivider),
          _vm.gs.isMobile() && _vm.notification.Status == 0
            ? _c(
                VRow,
                [
                  _c(
                    "div",
                    { staticClass: "notification-actions-buttons-container" },
                    [
                      _c(
                        VBtn,
                        {
                          class:
                            _vm.option == _vm.signatureNotificationsEnum.Confirm
                              ? "dxa-btn-primary"
                              : "dxa-btn-secondary",
                          staticStyle: { width: "100%" },
                          on: {
                            click: function ($event) {
                              _vm.option =
                                _vm.signatureNotificationsEnum.Confirm
                            },
                          },
                        },
                        [
                          _c(VIcon, { staticClass: "mr-1" }, [
                            _vm._v("mdi-check"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.$t("confirm")) + " "),
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          class:
                            _vm.option == _vm.signatureNotificationsEnum.Edit
                              ? "dxa-btn-primary"
                              : "dxa-btn-secondary",
                          staticStyle: { width: "100%" },
                          on: {
                            click: function ($event) {
                              _vm.option = _vm.signatureNotificationsEnum.Edit
                            },
                          },
                        },
                        [
                          _c(VIcon, { staticClass: "mr-1" }, [
                            _vm._v("mdi-pencil"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.$t("edit")) + " "),
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          class:
                            _vm.option == _vm.signatureNotificationsEnum.Decline
                              ? "dxa-btn-primary-danger"
                              : "dxa-btn-secondary-danger",
                          staticStyle: { width: "100%" },
                          on: {
                            click: function ($event) {
                              _vm.option =
                                _vm.signatureNotificationsEnum.Decline
                            },
                          },
                        },
                        [
                          _c(VIcon, { staticClass: "mr-1" }, [
                            _vm._v("mdi-close"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.$t("decline")) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.option == null
                    ? _c(VCol, { attrs: { cols: "12" } }, [
                        _c("p", { staticClass: "choose-option-msg" }, [
                          _vm._v(_vm._s(_vm.$t("choose_options_above"))),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            VForm,
            {
              ref: "form",
              staticClass: "mt-3",
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.submit()
                },
              },
            },
            [
              _vm.notification.Status == 2
                ? _c("div", [
                    _c("p", { staticClass: "consideration-title mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("justification"))),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "justification-declined-allocation-container",
                        staticStyle: {
                          background: "#e9e9e9",
                          "border-radius": "5px",
                        },
                      },
                      [
                        _c("p", { staticClass: "consideration-text pa-3" }, [
                          _vm._v(
                            " " + _vm._s(_vm.notification.ResponseText) + " "
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm.option == _vm.signatureNotificationsEnum.Edit
                ? _c(
                    "div",
                    { staticClass: "notification-input-money" },
                    [
                      _c("DXATextField", {
                        attrs: {
                          IsCurrency: true,
                          Currency: _vm.notification.CompanyInterest.Currency,
                          Field: _vm.$t("type_new_value"),
                          Text: _vm.notification.CompanyInterest.Value,
                          ErrorText: _vm.errorValue,
                          "data-test": "Notification:ManageModal:pNewValue",
                        },
                        on: {
                          update: function (value) {
                            _vm.notification.CompanyInterest.Value = value
                          },
                          text_changed: _vm.validate_value,
                        },
                      }),
                    ],
                    1
                  )
                : _vm.option == _vm.signatureNotificationsEnum.Decline
                ? _c(
                    "div",
                    { staticClass: "notification-input-money" },
                    [
                      _c("DXATextField", {
                        attrs: {
                          Field: _vm.$t("decline_reason"),
                          Text: _vm.notification.ResponseText,
                          ErrorText: _vm.errorResponse,
                          "data-test":
                            "Notification:ManageModal:txtDeclineReason",
                        },
                        on: {
                          update: function (value) {
                            _vm.notification.ResponseText = value
                          },
                          text_changed: _vm.validate_response,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VCardActions,
                {
                  staticClass: "mt-4 actions-button-container",
                  class: { "actions-buttons-start": _vm.option !== null },
                  staticStyle: { display: "flex" },
                },
                [
                  _vm.notification.Status != 0 || _vm.option == null
                    ? _c(
                        VBtn,
                        {
                          staticClass:
                            "dxa-btn-secondary btn-close-modal-notification",
                          attrs: { "min-width": "100", width: "50%" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                      )
                    : _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass:
                                "dxa-btn-secondary mx-3 notification-modal-button-width",
                              attrs: { "min-width": "100" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("close")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass:
                                "mx-3 notification-modal-button-width white-color",
                              class:
                                _vm.option ==
                                _vm.signatureNotificationsEnum.Decline
                                  ? "dxa-btn-primary-danger"
                                  : "dxa-btn-primary",
                              attrs: {
                                "min-width": "100",
                                loading: _vm.loading,
                                type: "submit",
                                "data-test":
                                  "Notification:ManageModal:Btn" +
                                  _vm.submit_btn_label,
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t(_vm.submit_btn_label)))]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }