import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { fluid: "", tag: "section" } },
    [
      _c(
        "p",
        {
          staticClass: "dark-color",
          staticStyle: {
            "font-style": "normal",
            "font-weight": "bold",
            "font-size": "25px",
            "line-height": "35px",
            "margin-bottom": "0px",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("suggested_allocation")) + " ")]
      ),
      _c(
        "span",
        {
          staticStyle: {
            "font-weight": "300",
            "font-size": "15px",
            "line-height": "35px",
            color: "#9c9c9c",
          },
        },
        [_vm._v(_vm._s(_vm.$t("suggested_allocation_desc")))]
      ),
      _c(
        VTabs,
        {
          staticClass: "dxa-tabs mt-4",
          attrs: {
            "show-arrows": "",
            grow: "",
            centered: "",
            "background-color": "transparent",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        _vm._l(_vm.tab_options, function (item, index) {
          return _c(
            VTab,
            {
              key: index,
              attrs: {
                "data-test":
                  "Notification:ReceivedNotifications:tab" + item.index,
              },
              on: {
                click: function ($event) {
                  return _vm.index_changed(item.index)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.title) + " ")]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "dxa-card mt-3", attrs: { id: "interest-table" } },
        [_c("ReceivedTemplate", { attrs: { Status: _vm.tab } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }